import React from 'react';
import { Link } from 'react-router-dom'; // For routing with React Router
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // For FontAwesome Icons
import { faFacebook, faLinkedin, faInstagram, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import './footer.css'; // Import CSS for styling
import footerLogo from '../../assets/images/okapi__2_-removebg-preview.png'; // Correct logo import


const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Adds smooth scrolling
    });
  };

  return (
    <footer className="footer">
      {/* Footer Main Section */}
      <div className="footer-logo-container">
          <div className="footer-logo">
            <img src={footerLogo} className="footer-logo-img" alt="Footer Logo" />
          </div>
          <div className="footer-address">
         <pre className="footer-address2">
          Get In Touch<br></br>
          Corporate Office:<br></br>
          M/s Okapi Green Automation PVT LTD<br></br>
          No.333/3A, GST Road, NH45, <br>
          </br>Silavattam Village, 
          <br></br>Madhuranthagam Tk.,
     <br></br>
     Chengalpattu Dt,<br></br>Tamil Nadu – 603306<br></br>
          Email:admin@okapigreenev.com<br></br>
          Phone: 044 2752 9091
        </pre></div>
        </div>

        {/* Footer Social Media and Copyright Section */}
      <div className="footer-social">
        <div className="footer-social-icon"> <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" className="social-icon" />
        </a>
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="2x" className="social-icon" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faXTwitter} size="2x" className="social-icon" />
        </a>
        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" className="social-icon" />
        </a></div>
       <div className="footer-copyrihts"> <p className="footer-copyright">
          © www.okapigreenev.com, All Rights Reserved.
        </p> </div>
       
      </div>
       {/* Footer Navigation Links */}
       <div className="footer-nav">
        <ul className="footer-links">
          <li  className="footer-links-h1">Quick Link</li>
          <li>
            <Link to="/" onClick={scrollToTop}>Home</Link>
          </li>
          <li>
            <Link to="/about" onClick={scrollToTop}>About Okapi</Link>
          </li>
          <li>
            <Link to="/innovative_okapi" onClick={scrollToTop}>Innovative Okapi</Link>
          </li>
          <li>
            <Link to="/product" onClick={scrollToTop}>Products & Applications</Link>
          </li>
          <li>
            <Link to="/distributor" onClick={scrollToTop}>Distributor</Link>
          </li>
          <li>
            <Link to="/contact" onClick={scrollToTop}>Contact</Link>
          </li>
        </ul>
      </div>

    </footer>
  );
};
export default Footer;