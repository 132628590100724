import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import top_image_fulllife from '../assets/images/ev-car-plugged-charging-point-showcasing-sustainable-energy-against-nature-backdrop-with-blue-energy-glow.jpg'; // Image import
import './inovative_okapi.css';
import footer_logo from '../assets/images/okapi5.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const Full_Life_Cycle_Utilization = () => {
  const [activeTab, setActiveTab] = useState("Full_Life_Cycle_Utilization");
  const [topImage, setTopImage] = useState(top_image_fulllife); // Default image for Full Life Cycle Utilization

  return (
    <div>
      <Navbar />

      {/* Top Banner Section */}
      <section className="top-container">
        <img src={topImage} alt="Top Banner" className="top-image" />
        <div className="content-overlay">
          <h1>Build a Green Lithium Battery Eco Chain</h1>
        </div>
      </section>

      {/* Tab Navigation */}
      <div className="m-tab m-tab-1">
        <div className="wp">
          <ul>
            {[
              { id: "RandD", label: "Research & Development", link: "/innovative_okapi" },
              { id: "achievements", label: "Achievements", link: "/achievement" },
              { id: "Intelligent_Manufacture", label: "Intelligent Manufacture", link: "/Intelligent_Manufacture" },
              { id: "Perfect_Quality", label: "Perfect Quality", link: "/Perfect_Quality" },
              { id: "Full_Life_Cycle_Utilization", label: "Full Life Cycle Utilization", link: "/Full_Life_Cycle_Utilization" }
            ].map((tab) => (
              <li
                key={tab.id}
                className={activeTab === tab.id ? "on" : ""}
                style={{ width: "20%" }}
                onClick={() => setActiveTab(tab.id)}
              >
                <a href={tab.link} style={{ textTransform: "uppercase" }}>
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Content Display Based on Active Tab */}
      <div>
        {activeTab === "Full_Life_Cycle_Utilization" && (
          <div id="Full_Life_Cycle_Utilization" className="about-content">
            <h1 className="head_randd">Full Life Cycle Utilization</h1>

            <div className="center-img">
              <img src={top_image_fulllife} alt="Full Life Cycle Utilization" />
            </div>
            <div className="center-div">
              {[...Array(5)].map((_, idx) => (
                <div key={idx} className="center-div-img"></div>
              ))}
            </div>
            <div className="overview-container">
              <div className="overview-image">
                <img src={top_image_fulllife} alt="Full Life Cycle Utilization" />
              </div>
              <div className="overview-text">
                <h2>Full Life Cycle Utilization</h2>
                <p>
                  Okapi Green EV embodies the perfect blend of technology and environmental consciousness,
                  transforming the manufacturing landscape through intelligent solutions.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Footer Section */}
      <footer>

<div className="footer-container">
<img src={footer_logo} style={{ height: 80 ,width: 80}} alt="Footer Logo" />
  <h1 style={{fontSize:50}}>OKAPI</h1>
<pre style={{color:'#fff'}}> No.333/3A, GST Road, NH45,Silavattam Village,<br></br>
Madhuranthagam Tk., Chengalpattu Dt,<br></br>
Tamil Nadu – 603306</pre>
</div>
<div style={{
  marginLeft:500,marginTop:-100  
}}>
<a href="#" style={{ marginLeft: -350}}>
<FontAwesomeIcon icon={faLinkedin} size="2xl"  style={{ color: "#2DD57E" }} />
</a>

<a href="" style={{marginLeft:30 }}>

<FontAwesomeIcon icon={faFacebook} size="2xl" style={{color: "#2DD57E",}} /></a>
<a href="" style={{marginLeft:30 }}><FontAwesomeIcon icon={faXTwitter} size="2xl" style={{ color: '#2DD57E' }} /></a>
<a href="" style={{marginLeft:30 }}><FontAwesomeIcon icon={faInstagram} size="2xl" style={{ color: "#2DD57E" }} /></a>
<p style={{marginLeft:200,marginTop:20,  fontFamily: "'Orbitron', sans-serif" }}>© www.okapigreenev.com, All Right Reserved.</p>
</div>

<div className="footer-container2">
<ul>
  <li><Link to="/">Home</Link></li>
  <li><Link to="/about">About Okapi</Link></li>
  <li><Link to="/innovative_okapi">Innovative Okapi</Link></li>
  <li><Link to="/product">Products & Applications</Link></li>
  <li><Link to="/distributor">Distributor</Link></li>
  <li><Link to="/contact">Contact</Link></li>
</ul>
</div>
</footer>
    </div>
  );
};

export default Full_Life_Cycle_Utilization;
