import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import topImage from "../assets/images/electric-car-docked-charging-station.jpg";
import "./inovative_okapi.css";
import footer_logo from '../assets/images/okapi5.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const IntelligentManufacture = () => {
  const [activeTab, setActiveTab] = useState("Intelligent_Manufacture");

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Top Banner Section */}
      <section className="top-container">
        <img src={topImage} alt="Top Banner" className="top-image" />
        <div className="content-overlay">
          <h1>
            BAK Power <br />
            Intelligent Manufacturing <br />
            Leads A Green Future
          </h1>
        </div>
      </section>

      {/* Tab Navigation */}
      <div className="m-tab m-tab-1">
        <div className="wp">
          <ul>
            {[
              { id: "RandD", label: "Research & Development", link: "/innovative_okapi" },
              { id: "achievements", label: "Achievements", link: "/achievement" },
              { id: "Intelligent_Manufacture", label: "Intelligent Manufacture", link: "/Intelligent_Manufacture" },
              { id: "Perfect_Quality", label: "Perfect Quality", link: "/Perfect_Quality" },
              { id: "Full_Life_Cycle_Utilization", label: "Full Life Cycle Utilization", link: "/Full_Life_Cycle_Utilization" }
            ].map((tab) => (
              <li
                key={tab.id}
                className={activeTab === tab.id ? "on" : ""}
                style={{ width: "20%" }}
                onClick={() => setActiveTab(tab.id)}
              >
                <a href={tab.link} style={{ textTransform: "uppercase" }}>
                  {tab.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Content Section */}
      <div id="Intelligent_Manufacture" className="about-content">
        <div className="overview-container">
          <div className="overview-image">
            <img src={topImage} alt="Intelligent Manufacture" />
          </div>
          <div className="overview-text">
            <h2>Intelligent Manufacture</h2>
            <p>
              Okapi Green EV embodies the perfect blend of technology and environmental
              consciousness, transforming the manufacturing landscape through intelligent solutions.
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer>

<div className="footer-container">
<img src={footer_logo} style={{ height: 80 ,width: 80}} alt="Footer Logo" />
  <h1 style={{fontSize:50}}>OKAPI</h1>
<pre style={{color:'#fff'}}> No.333/3A, GST Road, NH45,Silavattam Village,<br></br>
Madhuranthagam Tk., Chengalpattu Dt,<br></br>
Tamil Nadu – 603306</pre>
</div>
<div style={{
  marginLeft:500,marginTop:-100  
}}>
<a href="#" style={{ marginLeft: -350}}>
<FontAwesomeIcon icon={faLinkedin} size="2xl"  style={{ color: "#2DD57E" }} />
</a>

<a href="" style={{marginLeft:30 }}>

<FontAwesomeIcon icon={faFacebook} size="2xl" style={{color: "#2DD57E",}} /></a>
<a href="" style={{marginLeft:30 }}><FontAwesomeIcon icon={faXTwitter} size="2xl" style={{ color: '#2DD57E' }} /></a>
<a href="" style={{marginLeft:30 }}><FontAwesomeIcon icon={faInstagram} size="2xl" style={{ color: "#2DD57E" }} /></a>
<p style={{marginLeft:200,marginTop:20,  fontFamily: "'Orbitron', sans-serif" }}>© www.okapigreenev.com, All Right Reserved.</p>
</div>

<div className="footer-container2">
<ul>
  <li><Link to="/">Home</Link></li>
  <li><Link to="/about">About Okapi</Link></li>
  <li><Link to="/innovative_okapi">Innovative Okapi</Link></li>
  <li><Link to="/product">Products & Applications</Link></li>
  <li><Link to="/distributor">Distributor</Link></li>
  <li><Link to="/contact">Contact</Link></li>
</ul>
</div>
</footer>
    </div>
  );
};

export default IntelligentManufacture;
