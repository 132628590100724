import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import top_image_overview from '../assets/images/about.jpg';  // Image for Overview
import top_image_achievements from '../assets/images/ev-car-plugged-charging-point-showcasing-sustainable-energy-against-nature-backdrop-with-blue-energy-glow.jpg';  // Image for Achievements
import top_image_culture from '../assets/images/product1.jpg';  // Image for Corporate Culture
import './about.css'; // Ensure the CSS file is linked
import Footer from "../component/navbar/footer";

const About = () => {
  // State to track the active tab and top image
  const [activeTab, setActiveTab] = useState("overview");
  const [topImage, setTopImage] = useState(top_image_overview);  // Default image for Overview

  // Function to handle tab click and change image
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "overview":
        setTopImage(top_image_overview);
        break;
      case "achievements":
        setTopImage(top_image_achievements);
        break;
      case "culture":
        setTopImage(top_image_culture);
        break;
      default:
        setTopImage(top_image_overview);
    }
  };

  // Define the images for the AutoScrollList depending on the tab
  const getImagesForTab = (tab) => {
    switch (tab) {
      case "overview":
        return [
          { id: 1, title: "Overview Image 1", image: top_image_overview },
          { id: 2, title: "Overview Image 2", image: top_image_overview },
          { id: 3, title: "Overview Image 3", image: top_image_overview }
        ];
      case "achievements":
        return [
          { id: 1, title: "Achievements Image 1", image: top_image_achievements },
          { id: 2, title: "Achievements Image 2", image: top_image_achievements },
          { id: 3, title: "Achievements Image 3", image: top_image_achievements }
        ];
      case "culture":
        return [
          { id: 1, title: "Culture Image 1", image: top_image_culture },
          { id: 2, title: "Culture Image 2", image: top_image_culture },
          { id: 3, title: "Culture Image 3", image: top_image_culture }
        ];
      default:
        return [];
    }
  };

  return (
    <>
  
    <div>
      <section className="navclass">
    <Navbar/>
    </section>
      {/* Top Banner Section */}
      <section className="top-container">
        <img src={topImage} alt="Top Banner" className="top-image"  style={{height:600}}/>
      </section>

      {/* Tab Navigation */}
      <div className="m-tab m-tab-1" style={{marginTop:-390,width:'100%'}}>
        <div className="wp">
          <ul>
            <li
              className={activeTab === "overview" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("overview")}
            >
              <a href="#overview" style={{ textTransform: "uppercase" ,textDecoration:'none'}} >
                Overview
              </a>
            </li>
            <li
              className={activeTab === "achievements" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("achievements")}
            >
              <a href="#achievements" style={{ textTransform: "uppercase"  ,textDecoration:'none'}}>
                Achievements
              </a>
            </li>
            <li
              className={activeTab === "culture" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("culture")}
            >
              <a href="#culture" style={{ textTransform: "uppercase" ,textDecoration:'none' }}>
              Corporate Life
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Content Display Based on Active Tab */}

        {/* "Overview" Tab Content */}
        {activeTab === "overview" && (
              <div className="overview-text">
                <h2>Overview</h2>
                <p >
                Welcome to Okapigreen Automation Pvt Ltd, a pioneering force in the electric vehicle industry. We are dedicated to revolutionizing urban and last-mile transportation with our cutting-edge EV 3-wheelers, designed specifically for passenger and cargo solutions.</p>
                <p >At the heart of our mission is sustainability and innovation. We specialize in developing electric 3 wheelers that deliver efficiency, reliability, and environmental friendliness. Whether you're looking for a convenient passenger vehicle for city travel or a robust cargo solution for business operations, we provide vehicles that redefine performance and practicality.</p>   
                <p >Our team comprises forward-thinking engineers, designers, and industry experts passionate about creating solutions that reduce carbon footprints and promote green mobility. With a focus on advanced technology, user centric designs, and energy efficiency, we aim to be your trusted partner in transitioning to a cleaner, more sustainable future.</p>    
                <p >Okapigreen Automation Pvt Ltd is not just about vehicles it's about driving change, empowering businesses, and enhancing lives with smarter, greener transportation solutions. Together, we can build a better tomorrow.</p>       </div>
        )}

        {/* "Achievements" Tab Content */}
        {activeTab === "achievements" && (
            <div className="overview-text">
              <h2 >Achivements</h2>
              <h4>A Milestone Achievement</h4>
              <p >The inception of our electric vehicle (EV) company is deeply rooted in an inspiring milestone selling approximately 1,800 EVs to municipalities. This achievement was not just a breakthrough in sustainable transportation but also the defining moment that sparked the vision for a dedicated EV enterprise.</p>
              <h4>A Mission for Sustainable Mobility</h4>
              <p >The sale of 500 EVs highlighted the growing demand for environmentally-friendly transportation solutions. Municipalities recognized the importance of reducing their carbon footprint, and we were proud to provide vehicles that aligned with their sustainability goals.</p>

              <h4>Core Inspiration</h4>
              <p >This success served as a testament to the potential of electric mobility. It was a clear indication that the future of transportation lies in EVs. The overwhelming response from municipalities motivated us to take a step further and establish a company solely focused on advancing electric mobility.</p>
              <h4>Driving Forward</h4>
              <p >What started as a project has evolved into a full-fledged EV company, committed to innovation, sustainability, and making electric mobility accessible to all. This milestone remains a cornerstone of our journey, symbolizing the power of a vision backed by action.</p>
            </div>
    
        )}

        {/* "Corporate Culture" Tab Content */}
        {activeTab === "culture" && (
           <div className="overview-text1" >
             <h2 >Corporate Life</h2>
             <p >
             At Okapigreen Automation Pvt Ltd, we believe in fostering a dynamic and inclusive corporate culture that drives innovation and inspires excellence. As a leader in the electric vehicle industry, our workplace reflects the same principles of sustainability, collaboration, and forward-thinking that define our products.</p>
             <h3 className="h33">
             Our corporate life is built on the foundation of:</h3>
             <h4>1. Innovation and Creativity</h4>
              <p >
              We encourage our team to think outside the box and contribute groundbreaking ideas that shape the future of electric mobility. Every voice matters, and every idea has the potential to drive change.
              </p>
              <h4>2. Sustainability in Action</h4>
              <p >Just as our vehicles promote eco-friendliness, our corporate practices prioritize sustainability. From energy-efficient workplaces to green initiatives, we lead by example in reducing environmental impact.</p>
              <h4>3. Empowerment and Growth</h4>
              <p >We invest in our people. With ample opportunities for professional development, upskilling, and leadership training, we ensure our team members grow alongside the company.</p>
              <h4>4. Collaboration and Community</h4>
              <p >We are a team-driven organization where collaboration fuels success. Beyond work, we foster a sense of community through employee engagement activities, wellness programs, and team-building events.</p>
              <h4>5. Purpose Driven Work</h4>
              <p >Our mission is clear: to redefine mobility for a better planet. Being part of Okapigreen Automation Pvt Ltd means contributing to a cause bigger than ourselves creating a sustainable, greener future.
Corporate life at Okapigreen Automation Pvt Ltd is more than just work; it’s about making a difference, together. Join us, and be part of a company where your career aligns with your values, and your efforts leave a lasting impact.</p>
     </div>

        )}
      </div>

      {/* AutoScrollList with dynamic images based on active tab */}
      <div>
        {/* <AutoScrollList images={getImagesForTab(activeTab)} /> */}
      </div>
      <section style={{width:'100%'}}>
          <Footer/>
          </section>

    </>
  );
};

export default About;
