import React, { useState, useEffect } from 'react';
import Navbar from '../component/navbar/nav'; 
import top_image from '../assets/images/integrated-microchip-circuit-board-server-central-computer-processor-with-neon-lights.jpg';
import bottom_image from '../assets/images/ev-car-plugged-charging-point-showcasing-sustainable-energy-against-nature-backdrop-with-blue-energy-glow.jpg';
import image1 from '../assets/images/latest-hydrogen-fuel-storage-systems.jpg';
import image2 from '../assets/images/electric-car-docked-charging-station.jpg';
import './home.css';
import { Link } from 'react-router-dom';
import Footer from '../component/navbar/footer';

const Home = () => {
  const images = [image1, image2, bottom_image];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Cycle through images every 3 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="home-container">
      <Navbar />

      {/* Top Section */}
      <section className="top-container" style={{zIndex:-1}}>
        <img src={top_image} alt="Top Section" className="top-image1" />
      </section>

      {/* Center Section */}
      <section className="center-container">
        <div className="box-icon" style={{marginTop:-40}}>
          <ul className="list-icon">
          <Link to="/about" style={{textDecoration:'none'}}>
            <li className="item">
              <i className="fa-solid fa-caret-up"></i>About Okapi
            </li>
            </Link>
            <Link to="/innovative_okapi" style={{textDecoration:'none'}}>
            <li className="item">
              <i className="fa-solid fa-caret-up"></i>Innovative Okapi
            </li>
            </Link>
            <Link to="/product" style={{textDecoration:'none'}}>

            <li className="item">
              <i className="fa-solid fa-caret-up"></i>Products & Applications
            </li>
            </Link>
            <Link to="/distributor" style={{textDecoration:'none'}}>
            <li className="item">
              <i className="fa-solid fa-caret-up"></i>Distributor
            </li>
            </Link>
          </ul>
        </div>
      </section>
      <section className='footer'>
          <Footer/>
      </section>
    </div>
  );
};

export default Home;
