import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Product from './pages/Product';
import ContactForm from './pages/contact';
import InnovativeOkapi from './pages/inovative_okapi';
import Achievement from './pages/achivement';
import IntelligentManufacture from './pages/intelligent_Manufacture';
import PerfectQuality from './pages/Perfect_Quality';
import Full_Life_Cycle_Utilization from './pages/Full Life Cycle Utilization';
import Distributor from './pages/Distributor';



function App() {
  return (
    <div className="App">
      <Router> {/* Use BrowserRouter with Router */}
        <Routes>
          {/* Define routes */}
          <Route path="/" element={<Home />} />
          <Route path='/about' element={<About/>}/>
          <Route path='/product' element={<Product/>}/>
          <Route path='/contact' element={<ContactForm/>}/>
          <Route path='/innovative_okapi' element={<InnovativeOkapi/>}/>
          <Route path='/achievement' element={<Achievement/>}/>
          <Route path='/Intelligent_Manufacture' element={<IntelligentManufacture/>}/>
          <Route path='/Perfect_Quality' element={<PerfectQuality/>}/>
          <Route path='/Full_Life_Cycle_Utilization' element={<Full_Life_Cycle_Utilization/>}/>
          <Route path='/distributor' element={<Distributor/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
