import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import top_image_overview from '../assets/images/product1.jpg';
import top_image_achievements from '../assets/images/person.jpg';
import top_image_culture from '../assets/images/ev-car-plugged-charging-point-showcasing-sustainable-energy-against-nature-backdrop-with-blue-energy-glow.jpg';
import './Product.css';
import product from '../assets/images/OKAPI EV COMPANY PROFILE.jpg'
import Footer from "../component/navbar/footer";

const Product = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [topImage, setTopImage] = useState(top_image_overview);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "overview":
        setTopImage(top_image_overview);
        break;
      case "achievements":
        setTopImage(top_image_achievements);
        break;
      case "culture":
        setTopImage(top_image_culture);
        break;
      default:
        setTopImage(top_image_overview);
    }
  };

  return (
    <>
        <section className="navclass">
    <Navbar/>
    </section>
      <div>
        <section className="top-container">
          <img src={topImage} alt="Top Banner" className="top-image" style={{ height: 600 }} />
          <div className="content-overlay"></div>
        </section>

        <div className="m-tab m-tab-1" style={{ marginTop: -390 }}>
          <div className="wp">
            <ul>
              <li
                className={activeTab === "overview" ? "on" : ""}
                style={{ width: "33.3333%" }}
                onClick={() => handleTabClick("overview")}
              >
                <a href="#overview" style={{ textTransform: "uppercase",textDecoration:"none" }}>Our Products</a>
              </li>
              <li
                className={activeTab === "achievements" ? "on" : ""}
                style={{ width: "33.3333%" }}
                onClick={() => handleTabClick("achievements")}
              >
                <a href="#achievements" style={{ textTransform: "uppercase",textDecoration:"none"  }}>Upcoming Products</a>
              </li>
              <li
                className={activeTab === "culture" ? "on" : ""}
                style={{ width: "33.3333%" }}
                onClick={() => handleTabClick("culture")}
              >
                <a href="#culture" style={{ textTransform: "uppercase" ,textDecoration:"none" }}>Applications</a>
              </li>
            </ul>
          </div>
        </div>
        <div>
        {activeTab === "overview" && (
                           <img src={product} alt="Overview"  className="ourjourny"/>

        )}
          {activeTab === "achievements" && (
            <div id="achievements" style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem',
              padding: '2rem',
              backgroundColor: '#f9f9f9',
            }}>
              <div style={{
                maxWidth: '400px',
                width: '100%',
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div style={{ padding: '1.5rem', borderBottom: '1px solid #e0e0e0' }}>
                  <h2 style={{
                    fontSize: '1.5rem',
                    color: '#333',
                    marginBottom: '1rem',
                  }}>
                    Four Wheeler
                  </h2>
                </div>
              </div>
            </div>
          )}

          {activeTab === "culture" && (
            <div id="culture" style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '2rem',
              padding: '2rem',
              backgroundColor: '#f9f9f9',
            }}>
              <div style={{
                maxWidth: '400px',
                width: '100%',
                border: '1px solid #e0e0e0',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
              }}>
                <div style={{ padding: '1.5rem', borderBottom: '1px solid #e0e0e0' }}>
                  <h2 style={{
                    fontSize: '1.5rem',
                    color: '#333',
                    marginBottom: '1rem',
                  }}>
                    Under Progress
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
        <section style={{width:'100%'}}>
          <Footer/>
          </section>
      </div>
    </>
  );
};

export default Product;
