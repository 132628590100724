import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import top_image_overview from "../assets/images/innovation1.jpg"; 
import top_image_achievements from '../assets/images/ev-car-plugged-charging-point-showcasing-sustainable-energy-against-nature-backdrop-with-blue-energy-glow.jpg';  // Image for Achievements
import top_image_culture from '../assets/images/product.jpg';  // Image for Corporate Culture
import "./about.css";
import { Link } from 'react-router-dom';
import Footer from "../component/navbar/footer";
import jorney from '../assets/images/Journey.jpeg'

const InnovativeOkapi = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [topImage, setTopImage] = useState(top_image_overview); 
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    switch (tab) {
      case "overview":
        setTopImage(topImage);
        break;
      case "achievements":
        setTopImage(top_image_achievements);
        break;
      case "culture":
        setTopImage(top_image_culture);
        break;
      default:
        setTopImage(top_image_overview);
    }
  };
  return (
    <div>
   <section className="navclass">
    <Navbar/>
    </section>

      {/* Top Banner Section */}
      <section className="top-container">
        <img src={top_image_overview} alt="Top Banner"  style={{height:600}} className="top-image" />
      </section>
   {/* Tab Navigation */}
   <div className="m-tab m-tab-1" style={{marginTop:-390}}>
        <div className="wp">
          <ul>
            <li
              className={activeTab === "overview" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("overview")}
            >
              <a href="#overview" style={{ textTransform: "uppercase"  ,textDecoration:'none'}}>
               INNOVATION
              </a>
            </li>
            <li
              className={activeTab === "achievements" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("achievements")}
            >
              <a href="#achievements"  className="randd" style={{ textTransform: "uppercase"  ,textDecoration:'none'}}>
              Research and development 
              </a>
            </li>
            <li
              className={activeTab === "culture" ? "on" : ""}
              style={{ width: "33.3333%" }}
              onClick={() => handleTabClick("culture")}
            >
              <a href="#culture" style={{ textTransform: "uppercase"  ,textDecoration:'none'}}>
              our Journey
              </a>
            </li>
          </ul>
        </div>
      </div>
      {activeTab === "overview" && (

  
            <div className="overview-text">
              <h2 className="innovative-h2" >Innovation in Electric 3-Wheelers: Loader & Passenger</h2>
              <p >At the forefront of sustainable mobility, our electric 3-wheelers redefine innovation in both the passenger and loader segments. Designed to meet the diverse demands of modern transportation, our EVs combine advanced technology, eco-conscious design, and user-centric features to deliver unmatched performance and value.</p>
              <h4 >1. Smart Engineering for Maximum Efficiency</h4>
              <p >Our electric 3-wheelers are powered by cutting-edge lithium-ion batteries, ensuring longer range, faster charging, and superior energy efficiency. Whether you’re transporting passengers or carrying heavy loads, our vehicles optimize energy use to reduce operational costs and environmental impact.</p>
              <h4 >2. Durability Meets Versatility</h4>
              <p >Built with robust frames and high-strength materials, our EVs are engineered to withstand the rigors of daily use. For loaders, we offer exceptional payload capacity without compromising on stability, while our passenger variants prioritize comfort and safety, even on challenging terrains.</p>

              <h4 >3. Next-Gen Connectivity</h4>
              <p >Innovation meets intelligence with our integrated smart systems. Real-time tracking, remote diagnostics, and user-friendly dashboards ensure that operators have complete control over their vehicles, enhancing efficiency and minimizing downtime.</p>
              <h4 >4. Customizable Solutions</h4>
              <p >Understanding the unique needs of different industries, we offer tailored solutions for both loader and passenger variants. From modular cargo spaces to ergonomic seating layouts, every feature is designed to meet your specific requirements.</p>
            <p >Experience the future of urban mobility with our innovative electric 3-wheelers. Whether you’re driving progress in business or transforming everyday commutes, our vehicles empower you to lead the charge toward a smarter, greener tomorrow.</p>
            </div>

      )}
            {activeTab === "achievements" && (
      

            <div className="overview-text">
              <h2 className="innovative-h2">Research and development </h2>
              <h4 >IOT Based Digital Instrumental Cluster:</h4>
              <p >Revolutionizing the driving experience, our IoT-based instrumental cluster is designed to deliver real-time insights, seamless connectivity, and personalized control. By integrating advanced IoT technologies, we ensure that drivers have access to critical vehicle data at their fingertips, enabling smarter decisions and enhanced safety.</p>
              <h4 >Advanced V2G & Smart Ev Charging:</h4>
              <p >Our innovative V2G and smart charging solutions are designed to reshape energy utilization. By enabling two-way energy transfer between electric vehicles and the grid, we are creating a more efficient and sustainable energy ecosystem.</p>

              <h4 >Smart fleet management:</h4>
              <p >Optimize your fleet operations with our smart fleet management systems. Combining advanced telematics, AI-powered analytics, and IoT integration, we provide a comprehensive solution to monitor and enhance fleet performance.</p>
            </div>

      )}
           {activeTab === "culture" && (

              <img src={jorney} alt="Overview"  className="ourjourny"/>
  

       )}
 <section style={{width:'100%'}}>
          <Footer/>
          </section>
    </div>

  );
};

export default InnovativeOkapi;
