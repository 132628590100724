import React, { useState } from "react";
import Navbar from "../component/navbar/nav";
import top_image_overview from '../assets/images/product.jpg';
import top_image_achievements from '../assets/images/person.jpg';
import './Product.css';
import Footer from "../component/navbar/footer";

const Distributor = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [topImage, setTopImage] = useState(top_image_overview);


  return (
    <>
     <section className="navclass">
    <Navbar/>
    </section>
      <div>
        <section className="top-container">
          <img src={top_image_achievements} alt="Top Banner" className="top-image2" />
        </section>
             <div className="overview-text">
               <h2 >Distributor</h2>
               <h3>Become an Authorized Distributor for Okapigreen Automation Pvt Ltd</h3>
               <p style={{textAlign:"justify"}}>Join the Future of Transportation
               Partner with Okapigreen Automation Pvt Ltd, to bring sustainable and innovative electric vehicle solutions to your market. As one of the leading brands in the EV industry, we’re looking for dedicated and experienced distributors who share our vision for a cleaner, greener future.</p>
               <h4 >Why Partner with Us?</h4>
             
                <p style={{textAlign:"justify"}}>Cutting-Edge EV Technology: Gain access to our latest electric vehicle models, featuring state-of-the-art technology, superior performance, and innovative design.
               <br></br>High Demand, Growing Market: The EV market is expanding rapidly. By becoming a distributor, you’ll be at the forefront of this green revolution.
               <br></br>Comprehensive Support: Receive full support from our team, including marketing materials, training, and technical support, to help you succeed.</p>
    
          

<h4 className="our-ideal" >Our Ideal Partner</h4>
               <h3 style={{textAlign:"center"}}>We’re looking for distributors who are: </h3>

                <p style={{textAlign:"justify"}}>Passionate about sustainable energy and innovative technology.
                Experienced in automotive or electronics distribution.<br></br>
                Committed to exceptional customer service and brand representation.<br></br>
                </p>
  
                {/* <h4 >How to Apply</h4> */}
{/* 
                <p ><b>Contact Us :</b> connect to us for more details </p>
                <p ><b>Onboarding:</b> Once approved, you’ll receive everything you need to start representing Okapigreen Automation Pvt Ltd in your area.</p>
                <p ><b>Email:</b> admin@okapigreenev.com</p>
                <p ><b>Phone No:</b> 8072587828</p>
              <p >Be a part of the future of mobility. Start distributing Okapigreen Automation Pvt Ltd, electric vehicles and make a difference in your community and the world.</p>
            
           */}
             </div>
           </div>
  
        <section style={{width:'100%'}}>
          <Footer/>
          </section>
    </>
  );
};

export default Distributor;
