import React, { useState } from 'react';
import './contact.css'; // Import your custom CSS for the form
import Navbar from '../component/navbar/nav';
import Footer from "../component/navbar/footer";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    // Here, you would typically handle the form submission, like sending data to an API or backend.
  };

  return (
    <div >
      <Navbar />
      <div className="contact-form-container" >
        <form className="contact-form" onSubmit={handleSubmit} >
        <h2 className="form-title" >Contact Us</h2>
          {/* Name */}
          <div className="form-group">
            <label htmlFor="name">Your Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          {/* Message */}
          <div className="form-group">
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <div className="form-group">
            <button type="submit" className="submit-btn">Send Message</button>
          </div>
        </form>

        {/* Google Map */}
        <div className="map-container">
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d124657.15013060835!2d79.859927!3d12.480595!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a531b004576d42f%3A0x2d770f67f66de1ff!2sOkapi%20Green%20Automation!5e0!3m2!1sen!2sin!4v1732771042720!5m2!1sen!2sin"
        width="800"
        height="400"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
        </div>

        {/* Confirmation Message */}
        {submitted && <p className="confirmation-message">Thank you for contacting us! We will get back to you soon.</p>}
      </div>

      <Footer/>
    </div>
  );
};

export default ContactForm;